export default {
  name: "mailing",
  components: {},
  data() {

    return {
      checkednews:false,
      checkeddiscounts:false,
      checkedcosmetologist:false,
      checkededucational:false,
      checkedemail_letters:false,
      checkedtelegram:false,
      checkedviber:false,
      payload:{
        news:'Новости, акции',
        discounts:'Персональные скидки и предложения',
        cosmetologist:'Рекомендации косметолога',
        educational:'Обучающие программы',
        email_letters:'Email-письма',
        telegram:'Сообщения в Telegram',
        viber:'Сообщения в Viber',
      }
    };
  },
  methods: {}

};
